import React, { useEffect, useState, useContext } from 'react';
import {Navbar, Nav} from "react-bootstrap";
import { NavLink} from 'react-router-dom';
import {getPath} from '../tools/Regex';
import { ThemeContext } from "../ThemeContext/ThemeContext";
import { AddContext } from "../ThemeContext/AddContext";
import {BsPlusCircleFill, BsPlusCircle} from "react-icons/bs"
import Switch from "react-switch";
import './TopBar.css';

function TopBar () {
    const { toggle, toggleFunction, variantNavBar, bgNavBar, variantButton, bgForm, stylesToggle } = useContext(ThemeContext);
    const {addEvent, addEventFunction, handleCloseModal, modalData, setModalData, setNewModal} = useContext(AddContext);
    const [error, setError] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [path, setPath] = useState(null);
    
    useEffect(() => {
        let pathToExtract = getPath.exec(window.location.pathname);
        setPath(pathToExtract[0]);
    }, []);

    // On initialise le tableau de données à null pour pouvoir remplir un élément
    function addElement() {
        setModalData([]);
        setNewModal(true);
        addEventFunction();
    }

    const ButtonAdd = () => {
        // Si mode sombre
        if(toggle) {
            return (
                <BsPlusCircleFill style={stylesToggle.darkButton} onClick={addElement} />
            )
        }
        // Si mode clair
        else {
            return (
                <BsPlusCircle style={stylesToggle.lightButton} onClick={addElement} />
            )
        }
    }

    return (
        // <Navbar bg="dark" variant="dark" expanded={expanded} expand="lg" className="navbar" style={{fontSize:"120%", position:"fixed",top:0,width:"100%", zIndex:1000}}>
        <Navbar bg={bgNavBar} variant={variantNavBar} expanded={expanded} expand="lg" className="navbar" style={{fontSize:"120%", position:"fixed",top:0,width:"100%", zIndex:1000}}>
            <Navbar.Brand href="/">Inventaire</Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey={path} className="mr-auto">
                    {/* {!loading && !error && (menus.map((menu) => (
                        <NavLink key={menu.id} onClick={() => setExpanded(false)} className="nav-link" to={menu.path}>{menu.nom}</NavLink>
                    )))} */}
                    <NavLink key="1" onClick={() => setExpanded(false)} className="nav-link" to="/alimentaire/">Alimentaire</NavLink>
                    <NavLink key="2" onClick={() => setExpanded(false)} className="nav-link" to="/medicaments/">Médicaments</NavLink>
                    <div id="alignSwitch" className="nav-link">
                        Mode {toggle ? "Sombre" : "Clair" }
                        <Switch onChange={toggleFunction} checked={toggle} offColor="#ffffff" offHandleColor="#4B4F4F" onColor="#4B4F4F"  onHandleColor="#ffffff" />
                    </div>
                </Nav>
                <Nav activeKey={path} className="ml-auto">
                    {/* Open Modal to add an event */}
                    <ButtonAdd />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default TopBar;