import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./ThemeContext/ThemeContext";
import { AddProvider } from "./ThemeContext/AddContext";
import "./index.css";
import App from "./App";
import Liste from "./components/Liste/Liste";
import TopBar from "./Topbar/TopBar";
import EditProduit from "./components/Edit/EditProduit";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<ThemeProvider>
		<AddProvider>
			<Router>
				<TopBar />
                <EditProduit/>
				<Routes>
					<Route exact path="/" element={<App />} />
					<Route exact path="/:menu/" element={<Liste />} />
					{/* <Route exact path="/:menu/:name/" component={Detail}/> */}
				</Routes>
			</Router>
		</AddProvider>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
