import React, { useEffect, useState, useContext } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { convertDateToFormDate } from '../../tools/convertDateToFormDate';
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { AddContext } from "../../ThemeContext/AddContext";
import './EditProduit.css';

function EditProduit () {

    const { addEvent, addEventFunction, handleCloseModal, modalData, setModalData, newModal, setLaunchRefresh } = useContext(AddContext);
    const { toggle, toggleFunction, variantTable, variantButton } = useContext(ThemeContext);
    const [saveModif, setSaveModif] = useState(false);
    const [modalSuccessMsg, setModalSuccessMsg] = useState(false)

    const [produit, setProduit] = useState([]);
    const [resultMsg, setResultMsg] = useState(false);
    const [searchProduit, setSearchProduit] = useState(null);
    const [launchEdit, setLaunchEdit] = useState(false);
    // const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [erreur, setErreur] = useState([]);

    // url to fetch
    let categorie = window.location.pathname.slice(0,-1)
    let type = newModal ? 'ajout' : 'modification'
    const apiUrl = `/api/produits${categorie}/${type}`;
    
    function editLigne (id) {
        console.log(id)
    }

    function validChangement (donnees) {
        console.log('donnees :',donnees);
        setSaveModif(true);
    }


    useEffect( () => {

        // Si addEvent à false, alors on force le successModalMsg à false afin d'afficher le formulaire
        if(!addEvent) setModalSuccessMsg(false);

        if(saveModif) {
            
            const options = {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                method: 'POST',
                body: new URLSearchParams(modalData)
            }

            fetch(apiUrl, options)
            .then(res => res.json())
            .then(result => {
                console.log('result :', result);
                setResultMsg(result);
                setModalSuccessMsg(true);
            })
            .finally(() => {
                setSaveModif(false);
                setLaunchRefresh(true)
                handleCloseModal();
                // hideModal();
                // setShowSuccessModal(true)
            })
            .catch((e) => {
                setErreur(e);
                console.log('erreur :', e);
            })
        }

    }, [addEvent, saveModif])


    return (
        <Modal show={addEvent} onHide={addEventFunction} >
            <Modal.Header closeButton >
                <Modal.Title>Editer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                {!modalSuccessMsg && (
                    <Form>
                        <fieldset disabled={!newModal}>
                            <Form.Group >
                                <Form.Label>Nom : </Form.Label>
                                <Form.Control 
                                    value={modalData.nom}
                                    readOnly={!newModal}
                                    onChange={(element) => {
                                        setModalData(modalData => ({
                                            ...modalData,
                                            nom: element.target.value
                                        }))
                                    }} 
                                    type='text' 
                                    placeholder='Veuillez entrez le nom du produite'
                                />
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Code barre : </Form.Label>
                                <Form.Control 
                                    value={modalData.code_barre}
                                    readOnly={!newModal}
                                    onChange={(element) => {
                                        setModalData(modalData => ({
                                            ...modalData,
                                            code_barre: element.target.value
                                        }))
                                    }} 
                                    type='text' 
                                    placeholder='Veuillez entrer le numéro de code barre ou qr'
                                    />
                            </Form.Group>
                        </fieldset>
                        <Form.Group >
                            <Form.Label>Quantité : </Form.Label>
                            <Form.Control 
                                value={modalData.quantite}
                                onChange={(element) => {
                                    setModalData(modalData => ({
                                        ...modalData,
                                        quantite: element.target.value
                                    }))
                                }} 
                                type='number' 
                                placeholder='Veuillez entrer la quantité présente dans la réserve'
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Date de péremption : </Form.Label>
                            <Form.Control 
                                value={convertDateToFormDate(modalData.date_peremption)}
                                onChange={(element) => {
                                    setModalData(modalData => ({
                                        ...modalData,
                                        date_peremption: convertDateToFormDate(element.target.value)
                                    }))
                                }} 
                                type='date' 
                            />
                        </Form.Group>
                        <br/>
                        <Button className="float-end" variant={variantButton} onClick={()=>validChangement(modalData)}>Valider</Button>
                    </Form>
                )}
                {modalSuccessMsg && (
                    <Form>
                        {resultMsg.data}
                        {/* <Button className="float-end" variant={variantButton} onClick={()=>validChangement(modalData)}>Valider</Button> */}
                    </Form>
                )}
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )
}

export default EditProduit;