export const convertDateToFormDate = (date) => {
    let dateRegex = /\d*/g,
        newDate;
    try {
        newDate = date.match(dateRegex);
        newDate = newDate.filter(el => el !== '')

        let day   = newDate[0],
            month = newDate[1],
            year  = newDate[2];
        newDate = year+'-'+month+'-'+day
    }
    catch(e) {
        newDate = false;
    }
    return newDate
}