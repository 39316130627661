import React, {useState, useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Alert, Col } from 'react-bootstrap';
// import AddEditProduct from './Component/Edit';
import Liste from './components/Liste/Liste';
// import List from './Component/ListsFunction.js'


function App () {

    const [addProduct, setAddProduct] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState([]);
    const [product, setProduct] = useState([]);
    const [editProduct, setEditProduct] = useState(false) ;


    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isAddProduct: false,
    //         error: null,
    //         response: {},
    //         product: {},
    //         isEditProduct: false
    //     }
    //     this.onFormSubmit = this.onFormSubmit.bind(this);
    // }

    // onCreate() {
    //     setAddProduct({ isAddProduct: true, product:{} });
    // }
 
    // onFormSubmit(data) {
    //     if(data.cancel) {
    //         return this.setState({ isAddProduct: false, isEditProduct:false, product:{} });
    //     }
    //     let apiUrl;
    //     if (this.state.isEditProduct) {
    //         apiUrl = `${process.env.REACT_APP_API_URL}/products/edit`;
    //     }
    //     else {
    //         apiUrl = `${process.env.REACT_APP_API_URL}/products/create`;
    //     }
        
    //     const options = {
    //         headers: { 'Content-Type': 'application/json'},
    //         method: 'POST',
    //         body: JSON.stringify(data)
    //     };

    //     fetch(apiUrl, options)
    //         .then(res => res.json())
    //         .then(result => {
    //             this.setState({
    //                 response: result,
    //                 isAddProduct: false,
    //                 isEditProduct: false
    //             })
    //         },
    //             (error) => {
    //                 this.setState({ error });
    //             }
    //         )
    // }

    // editProduct = productId => {
    //     const apiUrl = `${process.env.REACT_APP_API_URL}/products`;
    //     let dataToJson = {id: productId}
        
    //     const options = {
    //         headers: { 'Content-Type': 'application/json'},
    //         method: 'POST',
    //         body: JSON.stringify(dataToJson)
    //     }

    //     fetch(apiUrl, options)
    //         .then(res => {
    //             return res.json()
    //         })
    //         .then(result => {
    //             this.setState({
    //                 product: result[0],
    //                 isAddProduct: true,
    //                 isEditProduct: true
    //             })
    //         },
    //             (error) => {
    //                 this.setState({ error });
    //             }
    //         )
    // }
    
    // let productForm;
    // if (this.state.isAddProduct || this.state.isEditProduct) {
    //     productForm = <AddEditProduct onFormSubmit={this.onFormSubmit}  product={this.state.product} />
    // }
    
    return (
        <div className="App">
            Bienvenue
            <br/>
            Veuillez sélectionner une catégorie
            {/* <Container> */}
                {/* {!this.state.isAddProduct && <Col md={2}> <Button variant="primary" className="AddButton" onClick={() => this.onCreate()}>Ajouter produit</Button> </Col>}
                {this.state.response.status === 'success' && <div><br /><Alert variant="info">{this.state.response.message}</Alert></div>}
                {!this.state.isAddProduct && <List editProduct={this.editProduct}/>}
                {productForm}
                {this.state.error && <div>Error: {this.state.error.message}</div>} */}
                {/* <List /> */}
            {/* </Container> */}
            {/* <Liste/> */}
        </div>
    );
}

export default App;
