import React, { useEffect, useState, useContext } from 'react';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import SuccessModal from '../SuccessModal'
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { AddContext } from "../../ThemeContext/AddContext";
import './Liste.css';
import EditProduit from '../Edit/EditProduit';
import { convertDateToFormDate } from '../../tools/convertDateToFormDate';

function Liste () {
    const { toggle, toggleFunction, variantTable, variantButton, bgForm } = useContext(ThemeContext);
    const { addEvent, addEventFunction, handleCloseModal, setModalData, setNewModal, launchRefresh, setLaunchRefresh } = useContext(AddContext);

    const [produits, setProduits] = useState([]);
    const [searchProduit, setSearchProduit] = useState(null);
    const [newQuantite, setNewQuantite] = useState(null);
    const [newDatePeremption, setNewDatePeremption] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    // const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [resultMsg, setResultMsg] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [saveModif, setSaveModif] = useState(false);
    const [dataEdit, setDataEdit] = useState([]);
    const [launchEdit, setLaunchEdit] = useState(false);
    const [erreur, setErreur] = useState([]);

    const categorie = window.location.pathname.slice(0,-1);
    const apiUrl = `/api/produits${categorie}`;
    const perime = (date) => {
        let dateFormated = new Date(convertDateToFormDate(date)),
            todayDate = new Date();
        if (dateFormated.getTime() < todayDate.getTime()) return 'ligneClassePerime'
        else return 'ligneClasse'
    };
    
    function editLigne (el) {
        setModalData(el);
        setNewModal(false);
        addEventFunction()
        // setShowModal(true);
    }
    
    function hideModal () {
        setShowModal(false);
        setModalData([]);
    }

    function hideSuccessModal () {
        setShowSuccessModal(false);
        setResultMsg([]);
    }

    function validChangement (donnees) {
        console.log('valide');
        console.log('donnees :',donnees);
        setSaveModif(true);
    }

    useEffect( () => {
        // if(saveModif) {
            
        //     const options = {
        //         headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        //         method: 'POST',
        //         body: new URLSearchParams(modalData)
        //     }

        //     fetch(apiUrl, options)
        //     .then(res => res.json())
        //     .then(result => {
        //         console.log('result :', result);
        //         setResultMsg(result);
        //     })
        //     .finally(() => {
        //         setSaveModif(false);
        //         setLaunchRefresh(true)
        //         hideModal();
        //         setShowSuccessModal(true)
        //     })
        //     .catch((e) => {
        //         setErreur(e);
        //         console.log('length erreur :', e);
        //     })
        // }
    }, [saveModif])

    useEffect( () => {
        if(launchRefresh) {
            let newUrl = searchProduit !== null ? apiUrl+"?produit="+searchProduit : apiUrl;
            fetch(newUrl)
            .then(res => res.json())
            .then((result) => {
                setProduits(result['data']);
            })
            .finally(() => {
                setLaunchRefresh(false);
            })
            .catch((e) => {
                setErreur(e);
                console.log('length erreur :', e);
            })
        }
    }, [launchRefresh])


    return (
        <div className={toggle ? "mainDivDark" : "mainDiv"}>
            {erreur.length > 0 && (
                <div> 
                    <br/>
                    <br/>
                    <h3>
                        <b>Erreur</b>
                    </h3>
                </div>
            )}
            {(erreur.length == 0 && produits) && (
                <div className="Main">
                    <Form className={bgForm}>
                        <Form.Group className="formSearchInput" controlId="formBasicEmail">
                            <Form.Label>Produit : </Form.Label>
                            <Form.Control 
                                onChange={(element) => {
                                    // On filtre en même temps que l'on cherche
                                    setLaunchRefresh(true);
                                    setSearchProduit(element.target.value);
                                }} 
                                type='search' 
                                placeholder='Veuillez chercher un produit par son nom ou code barre'
                            />
                        </Form.Group>
                    </Form>
                    <Table striped borderless hover variant={variantTable}>
                        <thead>
                            <tr>
                                <th scope="col">
                                    Nom
                                </th>
                                <th scope="col">
                                    Code Barre
                                </th>
                                <th scope="col">
                                    Date de péremption
                                </th>
                                <th scope="col">
                                    Quantité
                                </th>
                                <th scope="col">
                                    Dernière Mise à jour
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {produits.map((produit) => (
                                <tr key={produit.id} onClick={() => editLigne(produit)} className={perime(produit.date_peremption)}>
                                    <td>
                                        <b>{produit.nom}</b>
                                    </td>
                                    <td>
                                        {produit.code_barre}
                                    </td>
                                    <td className='dateAndNumberClass'>
                                        {produit.date_peremption}
                                    </td>
                                    <td className='dateAndNumberClass'>
                                        {produit.quantite}
                                    </td>
                                    <td className='dateAndNumberClass'>
                                        {produit.last_update}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    )
}

export default Liste;