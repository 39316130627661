import  React, {createContext, useEffect, useState}  from  "react";
// Context has been created
const AddContext  =  createContext(false);
// Provider
const AddProvider  =  ({ children })  =>  {
    const [addEvent, setAddEvent] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [newModal, setNewModal] = useState(false);
    const [launchRefresh, setLaunchRefresh] = useState(true);
    
    let handleCloseModal = !addEvent;
    
    let stylesToggle = {
        lightButton : {
            cursor: 'pointer',
            color: 'black'
        },
        darkButton : {
            cursor: 'pointer',
            color: 'white'
        }
    };

    function addEventFunction () {
		setAddEvent(!addEvent);
	};
return  (
    <AddContext.Provider value={{ addEvent, addEventFunction, handleCloseModal, modalData, setModalData, 
    newModal, setNewModal, launchRefresh, setLaunchRefresh }}>
        {children}
    </AddContext.Provider>
    );
};
export  { AddContext, AddProvider };